<template>
  <div>
    <component
      :is="print_type"
      :sale="sale"
      :hasAddress="hasAddress"
      :hasPhones="hasPhones"
      :phones="phones"
    />
  </div>
  <!-- -->
</template>

<script>
import common_printing from "@/components/sales/sections/SaleCommonPrinting.vue";
import thermal_printing from "@/components/sales/sections/SaleThermalPrinting.vue";
export default {
  components: {
    common_printing,
    thermal_printing,
  },

  data() {
    return {
      sale: {
        company: {
          main_address: {},
          phones: [],
        },
        customer: {
          main_address: {},
        },
        operation: {},
        salesman: {},
        sale_products: [{}],
      },
    };
  },

  async created() {
    this.$loading.start();

    await this.$http
      .show("sale/sale", this.$route.params.id)
      .then((response) => {
        this.sale = response.sale;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    print_type() {
      return this.$store.getters["app/getTypePrint"];
    },
    hasAddress() {
      return this.sale.company.main_address != null;
    },
    hasPhones() {
      return this.sale.company.phones.length > 0;
    },
    phones() {
      return this.sale.company.phones.map((phone) => phone.contact);
    },
  },
};
</script>

<style scope>
</style>

