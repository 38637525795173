<template>
  <span v-bind="$attrs">{{ paymentMethodLabel }}</span>
</template>

<script>

import paymentMethods from "../../../enums/paymentMethods";

export default {
  props: {
    paymentMethod : {}
  },

  computed : {
    paymentMethodLabel() {
      return paymentMethods.find(paymentMethod => paymentMethod.value == this.paymentMethod).label;
    },
  }
};
</script>

<style></style>
